body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f6f6f6;
}

.menuSectionH6 {
  background-color: #ffca28;
  padding: 16px;
  margin: 0px;
  border: 2px solid #ffca28;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 600;
  color: #333;
}
.menuSection {
  padding: 4px;
}
.menuSection-note {
  background-color: #fde8be;
  padding: 16px;
  margin: 0px;
  border: 2px solid #ffca28;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #333;
}

.menuItem {
  padding: 8px;
  margin: 8px;
  border: 2px solid #fde8be;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  :hover {
    background-color: #f6f6f6;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

.button {
  color: #0368f5 !important;
  background-color: #fbfbf8 !important;
  width: 100%;
  height: 50;
  border: 1px solid #e0e0e0;
  transition: all 0.3s ease;

  :hover {
    color: #fff !important;
    background-color: #0368f5 !important;
  }
}

.highlight {
  background-color: #0ed628 !important;
}

.noMatchingItems {
  font-size: 16px;
  color: #888;
  text-align: center;
  padding: 8px;
  margin-bottom: 16px;
}
